@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.rate-pizza {
  display: flex;
  flex-direction: column;
  width: 75%;
  @include bp.medium {
    width: 50%;
  }
  &__input-wrap {
    margin-bottom: 1rem;
  }
  &__input {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid v.$primary-color;
    width: 90%;
    margin-right: 2%;
    &:active {
      border: 1px solid v.$secondary-color;
    }
  }
  .taste-check,
  .leoparding-check,
  .mouth-feel-check,
  .caverns-check,
  .extensibility-check,
  .base-crustiness-check,
  .hot-rise-check,
  .rise-check {
    display: none;
  }

  .show {
    display: block;
  }

  &__form-error-text {
    color: v.$secondary-color;
    margin: 0.25rem 0 0rem 0;
    font-size: 0.8rem;
  }

  &__input-wrap {
    position: relative;
  }
  &__tooltip {
    position: relative;
    display: inline-block;
    background-color: v.$tertiary-color;
    width: 8%;
    border-radius: 5px;
    padding: 0.2rem;
    text-align: center;
  }

  &__tooltip-text {
    visibility: hidden;
    width: 180px;
    background-color: v.$tertiary-color;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -4px;
    right: 130%;
    font-size: 0.8rem;
    padding: 1rem;

    // for some reason ::after needs to be in its own breakpoints, becaus ethe proceeding styles don't override whats already written
    @include bp.small {
      &::after {
        content: "";
        position: absolute;
        top: 14px;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent v.$tertiary-color;
      }
    }
    @include bp.medium {
      left: 130%;

      &::after {
        content: "";
        position: absolute;
        top: 14px;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent v.$tertiary-color transparent transparent;
      }
    }
  }
  .rate-pizza__tooltip:hover .rate-pizza__tooltip-text {
    visibility: visible;
  }
  &__unit {
    padding-top: 0.5rem;
    margin-left: -45px;
    text-align: right;
    width: 30px;
    color: #8e8e8e;
    display: inline-block;
    font-size: 0.8rem;
    @include bp.medium {
      margin-left: -42px;
    }
  }
  &__button-wrap {
    text-align: center;
  }
}
