@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.pizza {
  display: flex;
  flex-direction: column;
  &__recipe-card {
    display: flex;
    flex-direction: column;
    border: 1px solid v.$secondary-color;
    background-color: v.$fourthiary-color;
    padding: 1rem;
    margin-bottom: 1rem;
    width: 95%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    @include bp.medium {
      width: unset;
    }
    &-title {
      margin-top: 0;
    }
  }
  &__name {
    margin: 0 0 1rem 0;
  }
  &__inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include bp.medium {
      flex-direction: row;
      justify-content: space-between;
      width: unset;
    }
    &-group {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include bp.medium {
        width: 48%;
      }
      border-bottom: 0.4px solid v.$secondary-color;
      margin-bottom: 1rem;
    }
    &-label {
      font-weight: 400;
      width: 75%;
      text-align: left;
    }
    &-item {
      color: v.$primary-color;
      width: 25%;
      text-align: right;
    }
  }
  &__suggested-amounts {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-group {
      display: flex;
      justify-content: space-between;
      width: 33%;
      margin-bottom: 1rem;
    }
    &-label {
      font-weight: 400;
      width: 75%;
      text-align: left;
    }
    &-item {
      color: v.$primary-color;
      width: 25%;
      text-align: right;
    }
  }
}
