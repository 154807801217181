@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.tools {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 2px solid v.$primary-color;
    &-link {
    }
    &-title {
    }
    &-img {
      max-width: 50%;
      border-radius: 5px;
      border: 2px solid #000;
    }
    &-description {
      width: 100%;
    }
  }
}
