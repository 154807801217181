/* resets */
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
ul {
  list-style: none;
}
a {
  font-family: inherit;
  text-decoration: none;
}
