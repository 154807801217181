@use "./variables" as v;

.button {
  background-color: v.$primary-color;
  border: 1px solid v.$primary-color;
  border-radius: 5px;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-family: "Roboto Mono", monospace;
  &:hover {
    background-color: v.$secondary-color;
    border: 1px solid v.$secondary-color;
  }
  &__google {
    @extend .button;
    background-color: v.$tertiary-color;
    border: 1px solid v.$tertiary-color;
    color: #000;
    &:hover {
      background-color: v.$secondary-color;
      border: 1px solid v.$secondary-color;
      color: #fff;
    }
  }
  &__delete {
    @extend .button;
    background-color: v.$secondary-color;
    border: 1px solid v.$secondary-color;
    color: #fff;
    &:hover {
      background-color: v.$tertiary-color;
      border: 1px solid v.$tertiary-color;
      color: #000;
    }
  }
  &__disabled {
    @extend .button;
    background-color: v.$primary-color;
    border: 1px solid v.$primary-color;
    opacity: 0.4;
    color: #fff;
    cursor: default;
    &:hover {
      background-color: v.$primary-color;
      border: 1px solid v.$primary-color;
      color: #fff;
    }
  }
  &__formula {
    @extend .button;
    background-color: v.$secondary-color;
    border: 1px solid v.$secondary-color;
    color: #fff;
    &:hover {
      background-color: v.$tertiary-color;
      border: 1px solid v.$tertiary-color;
      color: #000;
    }
  }
  &--full {
    @extend .button;
    width: 100%;
  }
}
