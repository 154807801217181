@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.pizza {
  &__name {
    margin: 0 0 1rem 0;
  }
  &__radar-chart {
    display: flex;
    justify-content: center;
    max-height: 80vh;
  }
  &__delete-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.caption {
  font-family: "Roboto Mono", monospace;
}
