@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.header {
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0; //this is a ul
    @include bp.medium {
      flex-direction: row;
      justify-content: space-around;
      // padding: 0 20%;
    }
  }

  &__nav-item {
    padding-top: 1rem;
  }

  &__nav-link {
    color: #000; //v.$secondary-color;
    letter-spacing: 0.2rem;
    padding-bottom: 1rem;
    /* Following styles effect fancy hovering*/
    display: block;
    position: relative;
    text-transform: uppercase;

    /* Styles so underline comes in from center of word */
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background-color: #000; //v.$secondary-color;
      transition: opacity 300ms, transform 300ms;
      width: 20%;
      margin-left: 40%;
      @include bp.medium {
        width: 100%;
        margin: 0;
      }
    }

    &::after {
      opacity: 1;
      transform: scale(0); /* Starts invisible */
      transform-origin: center;
    }

    &:hover::after,
    &:focus::after,
    &--active::after,
    &.active::after {
      transform: scale(1); /* Hover makes it NOT invisible */
    }
  }
}
