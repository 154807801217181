@mixin spinCW($num) {
  $spinVal1: (random(21) + 19);
  $spinVal2: (random(21) + 49);
  @keyframes spin-#{$num} {
    0%,
    #{$spinVal2}% {
      transform: rotateY(0deg) rotateX(360deg);
    }
    #{$spinVal1}%,
    100% {
      transform: rotateY(360deg) rotateX(0deg);
    }
  }
  $colorVal1: (random(25) + 0);
  $colorVal2: (random(50) + 26);
  $colorVal3: (random(75) + 51);
  $colorVal4: (random(100) + 76);
  @keyframes color-#{$num} {
    #{$colorVal1}% {
      border: 5px solid #f6416c;
    }
    #{$colorVal2}% {
      border: 5px solid #f8f3d4;
    }
    #{$colorVal3}% {
      border: 5px solid #00b8a9;
    }
    #{$colorVal4}% {
      border: 5px solid #ffde7d;
    }
  }

  //Apply animation
  .#{$num} {
    animation: spin-#{$num} (random(8) + 8) + s linear infinite,
      color-#{$num} (random(16) + 16) + s linear infinite;
    // :hover > & {
    //   animation-iteration-count: 1;
    // }
  }
}
// @include spinCW("one");
@include spinCW("two");
@include spinCW("three");
@include spinCW("four");
@include spinCW("five");
@include spinCW("six");
@include spinCW("seven");
@include spinCW("eight");
@include spinCW("center");

.wrap {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
}
span {
  display: block;
  border-radius: 100%;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
}
.center {
  width: 60%;
  height: 60%;
  border: 5px solid #f6416c;
  top: 70%;
  left: 70%;
}
.one {
  width: 10%;
  height: 10%;
  border: 5px solid #f6416c;
  top: 95%;
  left: 95%;
}
.two {
  width: 70%;
  height: 70%;
  border: 5px solid #f8f3d4;
  top: 65%;
  left: 65%;
}
.three {
  width: 80%;
  height: 80%;
  border: 5px solid #00b8a9;
  top: 60%;
  left: 60%;
}
.four {
  width: 90%;
  height: 90%;
  border: 5px solid #ffde7d;
  top: 55%;
  left: 55%;
}
.five {
  width: 100%;
  height: 100%;
  border: 5px solid #f6416c;
  top: 50%;
  left: 50%;
}
.six {
  width: 110%;
  height: 110%;
  border: 5px solid #f8f3d4;
  top: 45%;
  left: 45%;
}
.seven {
  width: 120%;
  height: 120%;
  border: 5px solid #00b8a9;
  top: 40%;
  left: 40%;
}
.eight {
  width: 130%;
  height: 130%;
  border: 5px solid #ffde7d;
  top: 35%;
  left: 35%;
}
