.progress {
  position: relative;
  height: 180px;
  width: 180px;
  border-radius: 180px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat no-repeat;
    mask-repeat: no-repeat no-repeat;
  }
}
