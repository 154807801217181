@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.footer {
  background-color: v.$tertiary-color;
  border-top: 2px solid #000; //v.$secondary-color;
  text-align: center;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include bp.medium {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10%;
  }
  &__heading {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 20px 0;
    text-align: left;
    text-align: center;
    @include bp.medium {
      text-align: left;
    }
  }
  &__section {
    display: inline-block;
    text-align: center;
    width: 33%;
    @include bp.medium {
      padding: 40px 5%;
      text-align: left;
      min-height: 275px;
    }
  }

  &__site-map {
    padding: 0;
    text-align: center;
    @include bp.medium {
      text-align: left;
    }
  }
  &__nav-item {
    padding-bottom: 1rem;
  }
  &__nav-link {
    color: v.$font-color;
  }
  &__social-icon {
    max-width: 40px;
  }
  &__link {
    color: #000;
    &:hover {
      color: v.$secondary-color;
    }
  }
}
