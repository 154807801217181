@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.pizza-list-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 2px solid v.$primary-color;
  background-color: v.$fourthiary-color;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  @include bp.medium {
    flex-direction: row;
  }
  &:hover {
    border: 2px solid v.$secondary-color;
  }
  &__name {
    color: #000;
  }
  &__rating {
    color: v.$primary-color;
  }
  &__radar-chart {
    display: flex;
    justify-content: center;
  }
}
