@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.pizza {
  text-align: center;
  &__ratings {
    display: flex;
    flex-direction: column;
    &-group {
      display: flex;
      justify-content: space-between;
      width: 95%;
      border-bottom: 0.4px solid v.$secondary-color;
      margin-bottom: 2rem;

      margin-left: auto;
      margin-right: auto;
      @include bp.medium {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
      }
    }
    &-label {
      font-weight: 400;
      padding-bottom: 0.5rem;
      &.average {
        font-weight: 600;
      }
    }
    &-item {
      color: v.$primary-color;
      text-align: right;
    }
  }
}
