@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.add-pizza {
  display: flex;
  flex-direction: column;
  width: 75%;
  @include bp.medium {
    width: 50%;
  }
  &__input-wrap {
    margin-bottom: 1rem;
  }
  &__input {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid v.$primary-color;
    width: 100%;
    &:active {
      border: 1px solid v.$secondary-color;
    }
  }

  .hydration-check,
  .salt-check,
  .cold-proving-time-check,
  .cold-proving-temp-check,
  .warm-proving-time-check,
  .warm-proving-temp-check,
  .ball-weight-check,
  .yeast-check {
    display: none;
  }

  .show {
    display: block;
  }

  &__form-error-text {
    color: v.$secondary-color;
    margin: 0.25rem 0 0rem 0;
    font-size: 0.8rem;
  }

  &__unit {
    padding-top: 0.5rem;
    margin-left: -35px;
    text-align: right;
    width: 30px;
    color: #8e8e8e;
    display: inline-block;
    font-size: 0.8rem;
    @include bp.medium {
      margin-left: -37px;
    }
  }
  &__button-wrap {
    text-align: center;
  }
  &__comingsoon {
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
}
