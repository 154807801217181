@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

.header {
  width: 100%;
  text-align: center;
  background-color: v.$tertiary-color; //v.$primary-color;
  border-bottom: 2px solid #000; //v.$secondary-color;

  &__user-links {
    display: flex;
    justify-content: center;
    @include bp.medium {
      justify-content: flex-end;
    }
    padding: 0.5rem 0.5rem 0 0;
    &-item {
      // padding: 0 1rem 0.5rem 0;
      color: #000;
    }
  }
  &__logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    @include bp.medium {
      padding-top: 0;
      flex-direction: column;
    }
  }
  &__chef-logo {
    max-width: 4rem;
    margin-right: 0.5rem;
  }
  &__text-logo {
    margin: 0;
    color: #000; //v.$secondary-color;

    @include bp.medium {
      padding-top: 0;
    }
    @include bp.large {
      // padding-bottom: 20px;
    }
  }

  &__desktop-nav {
    display: none;
    @include bp.medium {
      display: block;
    }
  }
  &__desktop-user {
    display: none;
    @include bp.medium {
      display: block;
    }
  }
  &__mobile-nav {
    background-color: v.$tertiary-color !important; // otherwise the plugins default style takes precedence
    .menu {
      background-color: v.$tertiary-color !important; // otherwise the plugins default style takes precedence
    }
  }
  .hamburger-react {
    display: block;
    @include bp.medium {
      display: none;
    }
  }
  .icon {
    display: none; // x in the mobile menu
  }
}

.blink {
  animation: blink-animation 1s steps(2, start) infinite;
  display: inline;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
