@use "./partials/variables" as v;
// @use './partials/fonts' as f;
@use "./partials/mixins" as m;
@use "./partials/breakpoints" as bp;

.App {
  z-index: -1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f7f8f9;
  font-family: "Roboto Mono", monospace;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  text-align: left;
  @include bp.medium {
    padding: 1rem 15%;
  }
  @include bp.large {
    padding: 1rem 20%;
  }
  &__block-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    @include bp.medium {
      padding-bottom: 4rem;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    @include bp.medium {
      flex-direction: row;
      padding-bottom: 4rem;
    }
    &-inner-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      &.order-1 {
        order: 1;
      }
      &.order-2 {
        order: 2;
      }
      @include bp.medium {
        width: 50%;
        padding-right: 1rem;
        justify-content: space-evenly;
        &.order-1 {
          order: unset;
        }
        &.order-2 {
          order: unset;
        }
      }
      img {
        max-width: 100%;
        border-radius: 5px;
      }
    }
    &-inner-right {
      @extend .content__block-inner-left;
      &.order-1 {
        order: 1;
      }
      &.order-2 {
        order: 2;
      }
      @include bp.medium {
        width: 50%;
        padding-left: 1rem;
        padding-right: 0;
        justify-content: space-evenly;
        &.order-1 {
          order: unset;
        }
        &.order-2 {
          order: unset;
        }
      }

      img {
        max-width: 100%;
        border-radius: 5px;
      }
    }
  }
  &__space-below {
    padding-bottom: 1rem;
  }
  &__page-heading {
    padding: 2rem 0 3rem 0;
    text-align: center;
    width: 70%;
    &-emojis {
      font-size: 2.5rem;
      margin: 0;
    }
    &-title {
      margin: 0;
      text-align: center;
      // font-family: "Roboto Slab", serif;
      font-family: "Oleo Script", cursive;
      font-size: 4rem;
      line-height: 0.8;
    }
  }

  &__title {
    margin: 0 0 1rem 0;
    // font-family: "Roboto Slab", serif;
    font-family: "Oleo Script", cursive;
    font-size: 3rem;
    line-height: 1.1;
    width: 100%;
    // @include bp.medium {
    //   width: 75%;
    //   margin-right: auto;
    // }
    &--center {
      @extend .content__title;
      text-align: center;
    }
  }
  &__page-title {
    @extend .content__title;
    text-align: center;
  }
  &__sub-title {
    @extend .content__title;
    font-family: "Roboto Mono", monospace;
    text-align: left;
    font-size: 1rem;
    width: 100%;
  }
  &__copy {
    text-align: left;
    margin: 0 0 1rem 0;
    width: 100%;
    &--highlight {
      @extend .content__copy;
      text-align: center;
      color: v.$secondary-color;
    }
    &--list {
      @extend .content__copy;
      color: v.$secondary-color;
      text-align: left;
      padding-left: 0;
      &-black {
        @extend .content__copy--list;
        color: #000;
      }
      .highlight {
        color: v.$secondary-color;
      }
    }
    &--center {
      @extend .content__copy;
      text-align: center;
    }
    &--border-top {
      @extend .content__copy;
      border-top: 2px solid v.$primary-color;
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
    }
  }
  a {
    color: v.$primary-color;
    text-align: center;
    &:hover {
      color: v.$secondary-color;
    }
  }
  &__logo {
    max-width: 15%;
    margin-bottom: 0.5rem;
  }
  &__final-pic {
    max-width: 75%;
    border-radius: 5px;
    @include bp.medium {
      max-width: 40%;
    }
  }
  &__goodery-ad-desktop {
    display: none;
    max-width: 100%;
    border: 1px solid black;
    margin-bottom: 4rem;

    @include bp.medium {
      display: block;
    }
  }
  &__goodery-ad-mobile {
    display: block;
    max-width: 100%;
    border: 1px solid black;
    margin-bottom: 3rem;

    @include bp.medium {
      display: none;
    }
  }
}

.green {
  color: v.$primary-color;
}
