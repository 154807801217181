@use "../../styles/partials/variables" as v;
// @use '../../styles/partials/fonts' as f;
@use "../../styles/partials/mixins" as m;
@use "../../styles/partials/breakpoints" as bp;

/* chart */
.shape {
  fill-opacity: 0.8; //0.3;
  &:hover {
    fill-opacity: 0.8; //0.65;
    z-index: 100;
  }
}
svg {
  overflow: visible;
  width: 95%;
  height: auto;
  margin-bottom: 1rem;
  @include bp.medium {
    width: 100%;
  }
}
.scale {
  fill: #ffde7d; //#fafafa;
  stroke: #999;
  stroke-width: 0.2;
}

.axis {
  stroke: #555;
  stroke-width: 0.2;
}

.dot {
  fill: white;
  stroke: #e7e8e7;
  r: 5;
  transition: r 0.2s;
  &:hover {
    stroke: #bbb;
    r: 8;
  }
}

.caption {
  fill: #444;
  font-weight: 400;
  text-shadow: 1px 1px 0 #fff;
}
